/* fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animated.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

/* fadeInUp */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInUp {
  animation: fadeInUp 1s ease-in-out;
}

/* fadeInDown */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInDown {
  animation: fadeInDown 1s ease-in-out;
}

/* fadeInRight */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInRight {
  animation: fadeInRight 1s ease-in-out;
}

/* fadeInLeft */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out;
}

.animate-delay-2{
  animation-delay: 2s;
}

@for $i from 1 through 10 {
  .animated.animate-delay-#{$i} {
    animation-delay: #{$i}s !important;
  }
}
@for $i from 1 through 10 {
  .animated.animate-duration-#{$i} {
    animation-duration: #{$i}s !important;
  }
}
