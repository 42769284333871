.content__breadcrumb {
  height: $breadcrumb-height;
  padding: $padding-block $padding-inline;
}

.breadcrumb {
  &-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $gray-700;
    @include h6-regular();
    line-height: 1;
    cursor: pointer;

    // @include media-breakpoint-down(sm) {
    //   align-items: start;
    // }

    &::before {
      padding-right: 0 !important;
      // height: 20px;
    }

  }

  a {
    text-decoration: none;
    color: $gray-700;
  }

  .icon-back {
    --svg: url('data:image/svg+xml,\
            <svg viewBox="0 0 100% 100%" xmlns="http://www.w3.org/2000/svg">\
            <path d="M3.41393 8.94842L18.9742 8.94842C19.2653 8.94842 19.509 9.04824 19.7054 9.24788C19.9018 9.44753 20 9.6953 20 9.99121C20 10.2871 19.9018 10.5349 19.7054 10.7345C19.509 10.9342 19.2653 11.034 18.9742 11.034L3.41393 11.034L10.4839 18.2215C10.6873 18.4283 10.7877 18.6703 10.7851 18.9475C10.7825 19.2247 10.6751 19.4711 10.4629 19.6868C10.2507 19.8882 10.0105 19.9925 9.74224 19.9997C9.47396 20.0068 9.23375 19.9025 9.02159 19.6868L0.349826 10.8709C0.221832 10.7408 0.131528 10.6035 0.0789161 10.4591C0.0263057 10.3148 -4.44054e-07 10.1588 -4.3673e-07 9.99121C-4.29405e-07 9.82364 0.0263057 9.66767 0.0789161 9.52329C0.131528 9.3789 0.221832 9.24164 0.349826 9.11151L9.02159 0.295612C9.21095 0.103083 9.44547 0.00459318 9.72514 0.000143791C10.0048 -0.0043056 10.2507 0.0941838 10.4629 0.295612C10.6751 0.511292 10.7811 0.759069 10.7811 1.03894C10.7811 1.31881 10.6751 1.56659 10.4629 1.78227L3.41393 8.94842Z"/>\
            </svg>\
            ');
    $icon-size: 20px;
    height: $icon-size;
    width: $icon-size;
    background-color: $gray-700;
    -webkit-mask: var(--svg);
    mask: var(--svg);
    transform: scale(.8);
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: nowrap;
  }
}