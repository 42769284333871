@import "scss/_commons";

$theme-colors: (
  "primary":    $primary-color,
  "secondary":  $secondary-color,
  "success":    $success-color,
  "info":       $info-color,
  "warning":    $warning-color,
  "danger":     $danger-color,
  "light":      $gray-50,
  "dark":       $gray-900
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1220px
);


  /* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

/*overwrite*/
@import "scss/base/_normalize";
@import "scss/base/_animation";

@import "scss/layouts/_layout";

@import "scss/components/_breadcrumb";
@import "scss/components/_form";
@import "scss/components/_badge";
@import "scss/components/_button";
@import "scss/components/_cards";
@import "scss/components/_tabs";
@import "scss/components/alert";
@import "scss/components/_accordion";
@import "scss/components/_cart-preview";
@import "scss/components/_modal";
@import "scss/components/_checkbox-switch";
@import "scss/components/_mat-form";
@import "scss/components/_mat-stepper";

@import "scss/components/_header.scss";

@import "scss/components/_product-card.scss";


//.dropdown-item.active, .dropdown-item:active {
//  color: var(--bs-dropdown-link-active-color);
//  background-color: var(--bs-dropdown-link-active-bg);
//}
