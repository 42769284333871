$head-height: 56px;

.product-card-grid {
  padding-block: $gap-half;

  &__item {
    width: 100%;
    margin-bottom: $gap;
    height: calc(100% - #{$gap});

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }
  }

  &.row {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 8px;
    }
  }
}

.product-card {
  position: relative;
  $border-radius: 4px;
  border-radius: $border-radius;
  border: 1px solid $gray-100;
  background: $pure-white;
  padding: 16px;
  min-height: 100%;

  @include media-breakpoint-down(sm) {
    padding: 8px;
  }

  &--subscription {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 30px;
      height: calc(100% - #{$mb*2});
      margin-block: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &--add {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      margin-bottom: 0px;
    }

    .dates {
      border-bottom: 1px solid $form-disable-bg;
      padding: 6px 0px;
    }

    .added-text {
      color: $secondary-color;
    }

    @include media-breakpoint-down(sm) {
      .product-card__body {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  &--check-address {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 20px;
      height: calc(100% - $mb);
      margin-top: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &__pre-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap-half;

    &.is-col-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;

    }
  }

  &__added {
    background-color: $primary-color;
    position: absolute;
    top: 82%;
    left: 0;
    height: 18%;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-inline: 10px;

    span {
      font-size: 2rem;
      opacity: 0.6;
    }

    h2 {
      @include h5-semibold;
      text-align: center;
      opacity: 0.6;
      margin: 0px;
    }

    @include media-breakpoint-down(sm) {
      span {
        font-size: 1.5rem;
      }

      h2 {
        @include h7-semibold;
      }
    }
  }

  /*product-card-head*/
  &__head {
    height: $head-height;

    a {
      text-decoration: none;
    }
  }

  &__title {
    @include h6-semibold;
    line-height: 1;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include media-breakpoint-down(sm) {
      @include h7-semibold;
      line-height: 1;
      height: 2em;
    }

    &.show-all {
      &:hover {
        height: 5ch !important;
        text-overflow: initial !important;
        white-space: normal !important; // white-space normal en lugar de text-wrap
      }
    }
  }

  &__subtitle {
    @include h8-regular;
    margin-bottom: 0;
    line-height: 1;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*product-card-body*/
  .product-card__img-wrap {
    width: 100%;
    position: relative;

    .info-badge {
      position: absolute;
      bottom: 20px;
      left: 10px;
    }

    .sale-badge {
      padding: 2px 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $danger-500;
      color: $danger-contrast-500;
      font-size: $size-8;
      border-radius: 20px;
    }
  }

  &__img {
    width: 100%;
    margin-bottom: $gap-half;
    object-fit: cover;
    aspect-ratio: 3 / 1.7;
  }

  &__meta {
    height: 70px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 0px;
  }

  &__meta--item {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    min-height: 40px;

    &.is-col-2 {
      //display: grid;
      //grid-template-columns: repeat(2, 50%);
      //@include media-breakpoint-down(lg) {
      //  grid-template-columns: 70% 30%;
      //}

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .quantity {
      max-width: 75px;

      @include media-breakpoint-down(xl) {
        max-width: 66px;
      }

      @include media-breakpoint-down(lg) {
        max-width: 65px;

        .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
          padding-inline: 5px;
        }
      }

      @include media-breakpoint-down(sm) {
        max-width: 30px;
      }
    }

    .product-card__price {
      @include h7-semibold;
      margin-bottom: 0;
      line-height: 1;

      @include media-breakpoint-down(sm) {
        font-size: $size-8;
      }
    }

    .product-card__price--line-through {
      @include media-breakpoint-down(sm) {
        font-size: calc($size-8 *.8);
      }

    }

    .product-card__info {
      @include h8-regular;
      height: 15px;
      margin-bottom: 0;
    }
  }

  &__action {
    margin-bottom: 12px;
    margin-top: 10px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 4px;
    }

    .btn {
      width: 100%;
      display: flex;
      height: 36px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      @include media-breakpoint-down(sm) {
        font-size: $size-8;
      }
    }

    .action-col-2 {
      display: flex;
      gap: $gap-half;

      .btn {
        white-space: nowrap;
      }

      @include media-breakpoint-down(md) {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
    }
  }

  &__foot {
    display: flex;

    &.is-col-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;

    }
  }

  .product-card__tags {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: $gap-half;
    height: 20px;

    li>img {
      width: 24px;
      height: 24px;
    }
  }

  .custom-checkbox {
    $height: 18px;
    height: $height;
    width: $height;
    position: relative;
    cursor: pointer;

    .fa-regular {
      color: gray;
    }

    .fa-heart {
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s ease-in-out;
    }

    .fa-solid {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .fa-solid {
      opacity: 0.5;
    }

    input[type="checkbox"]:checked~.fa-solid {
      opacity: 1;
    }
  }




  //used for rounded-selector arrow position
  .mat-mdc-select-empty,
  .mat-form-field-hide-placeholder,
  .mdc-floating-label--float-above+.mat-mdc-select {
    .mat-mdc-select-arrow svg {
      top: 10px;
    }
  }
}


.keen-slider__slide {
  max-width: 250px;
  min-width: 250px;

  app-product-card {
    max-width: 250px;
    min-width: 250px;

    .product-card__tags {
      display: none !important;
    }
  }
}